<template>
  <div class="panelfilter">

    <div class="elem-form">
      <label>
        Location:
      </label>
      <v-select
        :options="locations"
        placeholder="Location"
        v-model="params.location"
        :reduce="name => name.data"
        label="name"
      />
    </div>
    <div class="elem-form" v-if="techs.length > 0">
      <label>
        Technologies:
      </label>
      <div class="cont-techno">
        <div
          v-for="techno in techs"
          :key="techno.name"
          v-tooltip.bottom="{
            content: techno.name,
            delay: {
              show: 0,
              hide: 0,
            },
          }"
          @click="() => toggleOption(techno.name,'technologies') "
          :class="{selected: optionSelected(techno.name, 'technologies')}"
          class="radius box-shadow"
        >
          <img
              v-if="getPictoTechno(techno)"
              :src="require(`@/assets/pictos/${getPictoTechno(techno)}.svg`)"
          >
          <img
            v-else
            :src="require(`@/assets/pictos/technos/unspecified.svg`)"
          >
        </div>
      </div>
    </div>
    <div class="elem-form" v-if="skills.length > 0">
      <label>
        Skills:
      </label>
      <div class="cont-skills">
        <div
          class="radius elem-select-switch"
          v-for="skill in skills"
          :key="skill.id+'skill'"
          @click="() => toggleOption(skill.data,'skills') "
          :class="{blue: optionSelected(skill.data, 'skills')}"
        >
          {{skill.name}}
        </div>
      </div>
    </div>
    <div class="elem-form">
      <label>
        Certifications:
      </label>
      <div class="cont-btn">
        <div
          class="radius elem-select-switch"
          v-for="cert in certs"
          :key="cert.id+'cert'"
          @click="() => toggleOption(cert.data,'cert') "
          :class="{blue: optionSelected(cert.data, 'cert')}"
        >
          {{cert.name}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import technoList from '@/components/TalentComponents/talentTechnos/talentTechnoList';
import paramMixin from './paramMixin';

export default {
  name: 'TalentParams',
  mixins: [paramMixin('talent')],
  data() {
    const startingParams = this.$store.getters['search/talent/params'];
    return {
      params: {
        location: startingParams.location || '',
        technologies: startingParams.tech || [],
        skills: startingParams.skill || [],
        cert: startingParams.cert || [],
      },
      locations: this.mapOptions(['Paris', 'New York']),
      techs: this.mapOptions(technoList),
      skills: this.mapOptions(['skill1', 'skill2', 'skill3', 'skill4']),
      certs: this.mapOptions(['cert1', 'cert2', 'cert3', 'cert4']),

    };
  },
  mounted() {
    this.getSkillFromTalent();
    this.getTechnosFromTalent();
  },
  methods: {

    getSkillFromTalent() {
      const allSkills = [];
      this.$store.state.search.talent.entries.forEach((entrie) => {
        entrie.skills.forEach((entry) => {
          allSkills.push(entry);
        });
      });
      this.skills = this.mapOptions(allSkills.filter(
        (v, i) => allSkills.indexOf(v) === i,
      ));
    },
    getTechnosFromTalent() {
      const allTechno = [];
      this.$store.state.search.talent.entries.forEach((entrie) => {
        entrie.technologies.forEach((entry) => {
          if (this.findByMatchingProperties(technoList, { text: entry }).length > 0) {
            allTechno.push(this.findByMatchingProperties(technoList, { text: entry })[0]);
          } else {
            allTechno.push({
              text: entry,
              picto: '',
            });
          }
        });
      });
      this.techs = this.mapOptions(allTechno.filter(
        (v, i) => allTechno.indexOf(v) === i,
      ));
    },
    getPictoTechno(tech) {
      if (this.findByMatchingProperties(technoList, { text: tech.name }).length > 0) {
        return this.findByMatchingProperties(technoList, { text: tech.name })[0].picto;
      }
      return false;
    },
    findByMatchingProperties(set, properties) {
      return set.filter((entry) => Object.keys(properties).every(
        (key) => entry[key] === properties[key],
      ));
    },
  },

};
</script>

<style>

</style>
