<template>
  <div class="inner-offer-entry">
    <job-elem-list
      v-for="entry in entries"
      :key="entry._id"
      :elem="entry"
      :redirection="true"
      class="box-shadow radius-small"
    >
      <process-progress
        v-if="entry.hasOwnProperty('application')"
        :steps="entry.application.process"
      />
    </job-elem-list>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ProcessProgress from '@/components/EmployeeComponents/JobManagement/processPartials/processProgress.vue';
import jobElemList from '@/components/UniversalComponents/Offer/JobElemList.vue';

export default {
  name: 'OfferResults',
  components: {
    jobElemList,
    ProcessProgress,
  },
  computed: {
    ...mapState('search/offer', ['entries']),
  },
  methods: {
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  .inner-offer-entry{
    // font-family: 'Montserrat';
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    .elem-job {
      width: calc(100% - 2vh) !important;
      margin-bottom: 1.5vh;
      flex: 0 0 auto;
      &:nth-child(2n){
        margin-right: 0;
      }
      &:hover{
        opacity: 1;
        transform: scale3d(1.02,1.02,1.02);
      }
    }
  }
</style>
