<template>
  <div class="company-param panelfilter">

    <div class="elem-form">
      <label>
        Location:
      </label>
      <v-select
        :options="locations"
        v-model="params.location"
        placeholder="Location"
        :reduce="name => name.data"
        label="name"
      />
    </div>
    <div class="elem-form">
      <label>
        Field:
      </label>
      <div class="cont-btn full half">
        <div
          class="radius elem-select-switch"
          v-for="sector in sectors"
          :key="sector.id+'sector'"
          @click="() => toggleOption(sector.data,'sector') "
          :class="{blue: optionSelected(sector.data, 'sector')}"
        >
          {{sector.name}}
        </div>
      </div>
    </div>
    <!-- <div class="elem-form">
      <label>
        Parity:
        <div class="cont-switch-parity">
          <label>
            required:
          </label>
          <selectSwitch
            class="switch-parity"
            :dataSwitch="boolTrueFalse"
            v-model="parityRequired"
          />
          <ToggleSwitch
            :labelOff="''"
            :labelOn="''"
            :func="parityChange"
          ></ToggleSwitch>
        </div>
      </label>

    </div> -->
  </div>
</template>

<script>
import 'vue-slider-component/theme/default.css';
// import selectSwitch from '@/components/utilityPartials/selectSwitch.vue';
// import ToggleSwitch from '@/components/utilityPartials/ToggleSwitch.vue';
import paramMixin from './paramMixin';

export default {
  name: 'CompanyParams',
  components: {
    // ToggleSwitch,
  },
  mixins: [paramMixin('company')],
  data() {
    const startingParams = this.$store.getters['search/company/params'];
    return {
      params: {
        location: startingParams.location || [],
        sector: startingParams.sector || [],
        numEmployees: startingParams.numEmployees || null,
        // Location Dropdowm
        // Sector Checkbox
        // NumEmployees slider(range)
        // parity slider(1 num)

      },
      sectors: this.mapOptions(['AI', 'FinTech', 'BlockChain']),
      locations: this.mapOptions(['Paris', 'New York']),
      tempParity: 0.5,
      boolTrueFalse: {
        default: 1,
        data: [true, false],
      },
    };
  },

  mounted() {
    const allLocation = this.$store.state.search.company.entries.map((entrie) => (entrie.localisation !== undefined ? entrie.localisation : ''));
    const newallLocation = allLocation.filter((e) => typeof e === 'string' && e !== '');
    this.locations = this.mapOptions(newallLocation.filter(
      (v, i) => newallLocation.indexOf(v) === i,
    ));

    const allSectors = this.$store.state.search.company.entries.map((entrie) => (entrie.activitySector !== undefined ? entrie.activitySector : ''));
    const newallSectors = allSectors.filter((e) => typeof e === 'string' && e !== '');
    this.sectors = this.mapOptions(newallSectors.filter(
      (v, i) => newallSectors.indexOf(v) === i,
    ));
  },

};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .elem-form{
    position: relative
  }
</style>
<style lang="scss">
  @import "@/assets/scss/_variables.scss";

  button{
    display: block !important;
  }
  .contSlider {
    position: relative;
    padding-top: 4vh;
    width: 80%;
    .marker-sex{
      position: absolute;
      top: 1vh;
      width: 3vh;
      &.female{
        left: 0;
        transform: translateX(-30%);
      }
      &.male{
        right: 0;
        transform: translateX(40%);
      }
    }
    &.parity{
      width: 96%;
      margin: 0 auto;
      .vue-slider{
        .vue-slider-rail{
          border: 0.2vh solid $logo_color_dark;
          background-color: $green_color;
        }
        .vue-slider-process{
          background-color: $green_color;
        }
        .vue-slider-dot-handle{
          background-color: $green_color;
          box-shadow: $box_shadow_middle;
        }
        .vue-slider-dot-tooltip-inner{
          background-color: $logo_color;
          .vue-slider-dot-tooltip-text{
            font-size: $classic_min_fs;
            text-decoration: none;
          }
          &::after{
            border-top-color: $logo_color;
          }
        }
      }
    }
  }
  .cont-switch-parity{
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    > span{
      display: block;
      margin-right: 1vh;
      font-size: $classic_min_fs !important;
    }
    .select-switch{
      &.switch-parity{
        .elem-select-switch{
          padding: 0 0.5vh 0.3vh 0.5vh;
          span{
            font-family: 'MontserratRegular';
            text-align: center;
            font-size: $classic_min_fs;
          }
        }
      }
    }
    .switch {
      .inner_switch{
        input {
          &:checked + .slider {
            background-color: $green_color;
          }
          &:focus + .slider {
            box-shadow: 0 0 0.1vh $green_color;
          }
        }
        .slider {
          background-color: $logo_color_dark;
        }
      }
    }
  }
</style>
