<template>
  <div class="inner-company-entry">
    <div
      class="elem-company-search box-shadow radius"
      v-for="entry in entries" :key="entry._id"
      @click.exact="() => redirect(entry._id)"
      @click.ctrl="() => rightClick(entry._id)"
      @auxclick="() => rightClick(entry._id)"
    >
      <div class="top-company-search">
        <div class="left-company-search">
          <div
            class="profil-pic"
            :style="`background-image:url(${entry.companyPicture})`"
          ></div>
        </div>
        <div class="right-company-search">
          <div class="name-company" v-if="entry.name">
            <p>{{entry.name}}</p>
          </div>
        </div>
      </div>
      <div class="bottom-company-search">
          <div class="elem-sub" v-if="entry.localisation">
            <div class="picto-sub">
              <img :src="require('@/assets/pictos/picto_localisation_darkpurple.svg')">
            </div>
            <div class="info-sub">
              {{entry.localisation}}
            </div>
          </div>
          <div class="elem-sub" v-if="entry.numEmployees">
            <div class="picto-sub">
              <img :src="require('@/assets/pictos/picto_team_darkpurple.svg')">
            </div>
            <div class="info-sub">
              {{entry.numEmployees}}
            </div>
          </div>
          <div class="elem-sub" v-if="entry.webSite">
            <div class="picto-sub">
              <img :src="require('@/assets/pictos/picto_team_darkpurple.svg')">
            </div>
            <div class="info-sub">
              {{entry.webSite}}
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CompanyResults',
  components: {
  },
  computed: {
    ...mapState('search/company', ['entries']),
  },
  methods: {
    redirect(companyID) {
      this.$router.push(`/company/${companyID}`);
    },
    rightClick(companyID) {
      window.open(`${window.location.origin}/company/${companyID}`, '_blank');
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  .inner-company-entry{
    // font-family: 'Montserrat';
    display: flex;
    flex-wrap: wrap;
    .elem-company-search{
      width: calc(calc(100% - 1.5vh) / 2);
      margin-right: 1.5vh;
      background-color: $background_gray;
      margin-bottom: 1.5vh;
      display: flex;
      flex: 0 1 auto;
      flex-direction: column;
      align-items: flex-start;
      cursor: pointer;
      transition: all 0.1s;
      &:hover{
        transform: scale3d(1.02,1.02,1.02);
      }
      &:nth-child(2n){
        margin-right: 0;
      }
      .top-company-search{
        display: flex;
        align-items: center;
        .left-company-search{
          padding: 1vh 0 1vh 1vh;
          .profil-pic{
            height: 4vh;
            width: 4vh;
            background-color: $logo_color;
            border-radius: 50%;
            background-size: cover;
            background-position: 50% 50%;
          }
        }
        .right-company-search{
          padding: 1vh;
          .name-company{
            p{
              color: $logo_color;
              font-size: 2vh;
              width: 10vw;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-family: 'MontserratBold';
              margin: 0;
              padding: 0;
            }
          }
        }
      }
      .bottom-company-search{
        display: flex;
        flex-wrap: wrap;
        width: calc(100% - 2vh);
        padding: 1vh;
        .elem-sub{
          min-width: calc(calc(100% - 1.5vh) / 2);
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;
          margin-right: 1.5vh;
          &:nth-child(2n){
            margin-right: 0;
          }
          .picto-sub{
            position: relative;
            width: 25px;
            height: 25px;
            margin-right: 5px;
            img{
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateY(-50%) translateX(-50%);
              width: 160%;
            }
          }
          .info-sub{
            font-size: $mini_fs;
            color: $logo_color_dark;
          }
        }
      }
    }
  }
</style>
