import companyResults from './companyResults.vue';
import offerResults from './offerResults.vue';
import postResults from './postResults.vue';
import talentResults from './talentResults.vue';

export default [
  {
    name: 'company',
    component: companyResults,
    picto: 'picto_companies',
  },
  {
    name: 'offer',
    component: offerResults,
    picto: 'picto_jobs',
  },
  {
    name: 'talent',
    component: talentResults,
    picto: 'picto_talents',
  },
  {
    name: 'post',
    component: postResults,
    picto: 'picto_feed',
  },
];
