import companyParams from './companyParams.vue';
import talentParams from './talentParams.vue';
import postParams from './postParams.vue';
import offerParams from './offerParams.vue';

export default [
  {
    name: 'company',
    component: companyParams,
  },
  {
    name: 'offer',
    component: offerParams,
  },
  {
    name: 'talent',
    component: talentParams,
  },
  {
    name: 'post',
    component: postParams,
  },
];
