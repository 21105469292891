<template>
  <div
    class="elem_global_nav"
    :key="elem.name"
    @click.exact="click"
    @click.ctrl="rightClick"
    @auxclick="rightClick"
  >
    {{elem.name}}
    <div class="underline"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ElemNav',
  props: {
    click: Function,
    elem: {},
    rightClick: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState('search', ['current']),
  },
  components: {
  },
  methods: {
  },
  mounted() {
  },
};
</script>

<style lang="scss">
    @import "@/assets/scss/_variables.scss";
    .elem_global_nav{
      font-size: 2vh;
      font-weight: 600;
      margin-right: 1vw;
      cursor: pointer;
      margin-right: 3vw;
      position: relative;
      .underline{
        position: absolute;
        width: 0;
        background-color:#8c28e1 ;
        border-radius: 0.25vh;
        margin-top: 1vh;
        height: 4px;
        border-radius: 2px;
        transition: all 0.2s;

      }
      &:last-child{
        margin-right: 0;
      }
      &.current{
        font-family: "MontserratBold";
        .underline{
          width: 50%;
        }
      }
    }
</style>
