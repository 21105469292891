<template>
  <div class="panelfilter">
    <div class="elem-form">
      <label>
        From:
      </label>
      <v-select
        :clearable='false'
        :searchable="false"
        v-model="params.days"
        :options="fromDate"
        placeholder="From date"
        :reduce="name => name.data"
        label="name"
        class="no-remove"
      />
    </div>
    <div class="elem-form">
      <label>Post Type:</label>
      <div class="cont-btn">
        <div
          class="radius elem-select-switch"
          v-for="type in mediaType"
          :key="type.id+'media'"
          @click="() => changeSelection(type.data,'mediaType') "
          :class="{blue: optionSelected(type.data, 'mediaType')}"
        >
          {{type.name}}
        </div>
      </div>
    </div>
    <!-- <div class="blockfilter">
      <span>Theme:</span>
      <div class="cont-tag">
        <button
          v-for="t in theme"
          :key="t.id+'theme'"
          @click="() => toggleOption(t.data,'theme') "
          :class="{blue: optionSelected(t.data, 'theme')}"
        >
          {{t.name}}
        </button>
      </div>
    </div> -->
  </div>
</template>

<script>
import paramMixin from './paramMixin';

export default {
  name: 'PostParams',
  mixins: [paramMixin('post')],
  data() {
    const startingParams = this.$store.getters['search/post/params'];
    return {
      params: {
        mediaType: startingParams.mediaType || 'All',
        theme: startingParams.theme || [],
        days: startingParams.days || 0,
        // Type: [POST, MEDIA, VIDEOS, ALL ] CHECKBOX,
        // Theme: CHECKBOX?
      },
      mediaType: this.mapOptions(['All', 'Post', 'Video', 'Media']),
      theme: this.mapOptions(['Theme1', 'Theme2', 'Theme3']),
      fromDate: [{ name: 'Today', data: 1 }, { name: 'Week', data: 7 }, { name: 'Month', data: 30 }, { name: 'All', data: 0 }],
    };
  },

};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
</style>
