<template>
<div class="inner-talent-entry">
  <div
    class="elem-talent-search box-shadow radius"
    v-for="entry in entries" :key="entry._id"
    @click.exact="() => redirect(entry._id)"
    @click.ctrl="() => rightClick(entry._id)"
    @auxclick="() => rightClick(entry._id)"
  >
    <div class="left-talent-search">
      <div class="profil-pic" :style="`background-image:url(${entry.profilePicture})`"></div>
    </div>
    <div class="right-talent-search">
      <div class="name-talent" v-if="entry.name">
        <p>{{entry.name.firstName}} {{entry.name.lastName}}</p>
      </div>
      <div class="job-talent" v-if="entry.jobWanted">
        <p>{{entry.jobWanted}}</p>
      </div>
      <div class="job-talent" v-else>
        <p>My dream job</p>
      </div>
    </div>
  </div>
  <!-- <talentCard v-for="entry in entries" :key="entry._id" :elem="entry" /> -->
</div>

</template>

<script>
import { mapState } from 'vuex';
// import talentCard from
// '@/components/UniversalComponents/Search/Results/specificCards/talentCard.vue';

export default {
  name: 'TalentResults',
  components: {
    // talentCard,
  },
  computed: {
    ...mapState('search/talent', ['entries']),
  },
  methods: {
    redirect(talentID) {
      this.$router.push(`/talent/${talentID}`);
    },
    rightClick(talentID) {
      window.open(`${window.location.origin}/talent/${talentID}`, '_blank');
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  .inner-talent-entry{
    // font-family: 'Montserrat';
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    .elem-talent-search{
      width: calc(calc(100% - 3vh) / 3);
      margin-right: 1.5vh;
      background-color: $background_gray;
      margin-bottom: 1.5vh;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all 0.1s;
      &:hover{
        transform: scale3d(1.02,1.02,1.02);
      }
      &:nth-child(3n){
        margin-right: 0;
      }
      .left-talent-search{
        padding: 1vh 0 1vh 1vh;
        .profil-pic{
          height: 4vh;
          width: 4vh;
          background-color: $logo_color;
          border-radius: 50%;
          background-size: cover;
          background-position: 50% 50%;
        }
      }
      .right-talent-search{
        padding: 1vh;
        .name-talent{
          p{
            width: 10vw;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: 'MontserratBold';
            margin: 0;
            padding: 0;
          }
        }
        .job-talent{
          p{
            font-weight: 400;
            font-size: 1.2vh;
            color: $border_darkgray;
            width: 10vw;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }
</style>
