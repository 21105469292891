<template>
  <div class="panelfilter">

    <div class="elem-form">
      <label>
        From:
      </label>
      <v-select
        :clearable='false'
        :searchable="false"
        v-model="params.days"
        :options="fromDate"
        placeholder="From dqte"
        :reduce="name => name.data"
        label="name"
        class="no-remove"
      />
    </div>
    <div class="elem-form">
      <label>
        Location:
      </label>
      <v-select
        :options="locations"
        v-model="params.location"
        placeholder="Location"
        :reduce="name => name.data"
        label="name"
        :clearable="false"
      />
    </div>
    <div class="elem-form">
       <label>
        Remote Work:
      </label>
      <div class="cont-btn">
        <div
          class="radius elem-select-switch search-switch"
          v-for="option in remoteOptions"
          :key="option+'remote'"
          @click="() => toggleOption(option,'remote') "
          :class="{blue: optionSelected(option, 'remote')}"
        >
          {{option}}
        </div>
      </div>
    </div>
    <div class="elem-form">
      <label>
        Job Type:
      </label>
      <div class="cont-btn full half">
        <div
          class="radius elem-select-switch search-switch"
          v-for="contract in contracts"
          :key="contract.id+'contract'"
          @click="() => toggleOption(contract.data,'contract') "
          :class="{blue: optionSelected(contract.data, 'contract')}"
        >
          {{contract.name}}
        </div>
      </div>
    </div>
    <div class="elem-form">
      <label>
        Field:
      </label>
      <div class="cont-btn full half">
        <div
          class="radius elem-select-switch search-switch"
          v-for="sector in sectors"
          :key="sector.id+'sector'"
          @click="() => toggleOption(sector.data,'sector') "
          :class="{blue: optionSelected(sector.data, 'sector')}"
        >
          {{sector.name}}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import paramMixin from './paramMixin';

export default {
  name: 'OfferParams',
  mixins: [paramMixin('offer')],
  components: {
  },

  data() {
    const startingParams = this.$store.getters['search/offer/params'];
    return {
      params: {
        days: startingParams.days || 0,
        location: startingParams.location || [],
        contract: startingParams.contract || [],
        sector: startingParams.sector || [],
        remote: startingParams.remote || [],
      },
      sectors: this.mapOptions(['AI', 'FinTech', 'BlockChain']),
      contracts: this.mapOptions(['Full-Time', 'Freelance', 'Part-Time', 'Internship']),
      locations: this.mapOptions(['Paris', 'New York']),
      fromDate: [{ name: 'Today', data: 1 }, { name: 'Week', data: 7 }, { name: 'Month', data: 30 }, { name: 'All', data: 0 }],
      remoteRequired: false,
      remoteOptions: ['Yes', 'No', 'Partial'],
    };
  },
  methods: {
    setRemote(val) {
      this.toggleOption(val, 'remote');
    },
  },
  mounted() {
    setTimeout(() => {
      const allLocation = this.$store.state.search.offer.entries.map((entrie) => (entrie.location !== undefined ? entrie.location : ''));
      const newallLocation = allLocation.filter((e) => typeof e === 'string' && e !== '');
      this.locations = this.mapOptions(newallLocation.filter(
        (v, i) => newallLocation.indexOf(v) === i,
      ));
    }, 1000);
  },

};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.cont-btn{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0;
  width: 100%;
  &.full{
    &.tier{
      .elem-select-switch{
        width: calc(calc(calc(100% - 2vh) / 3) - 4vh);
      }
    }

    &.half{
      .elem-select-switch{
        width: calc(calc(calc(100% - 1vh) / 2) - 4vh);
        margin-bottom: 1vh;
        &:nth-child(2n){
          margin-right: 0;
        }
      }
    }
  }
}
.elem-select-switch{
  &.search-switch{
    text-align: center;
    font-size: $fnt-12;
    width: calc(calc(calc(100% - 3vh) / 4) - 4vh);
    text-align: center;
    padding: 1.25vh 2vh;
    cursor: pointer;
    margin-right: 1vh;
    background-color: #ffffff;
    &:last-child{
      margin-right: 0;
    }
    &.blue{
      color: #ffffff;
      border: 0;
      background-color: $logo_color;
    }
    &:hover{
      background-color: $green-color;
      color: $text_color;
    }
  }
}
</style>
