<template>
<div class="component-fullwidth">
  <div class="flex-container flex-full no-padding no-margin">
    <div class="elem-flex">
      <div class="title-page">
        Search
      </div>
    </div>
  </div>
  <div class="inner-container">
    <div class="search-container radius box-shadow">
        <search-bar />
    </div>
    <div class="cont-select-result-type">
      <div class="subtitle-page">
        &nbsp;
      </div>
      <div class="cont-inner-nav">
        <newElemNav
          v-for="tab in tabs"
          :key="tab.name"
          :click="() => changeTab(tab.nametab)"
          :rightClick="() => newTab(tab.nametab)"
          :elem="tab"
          :class="(tab.nametab === current) ? 'current' : ''"
        >
        </newElemNav>
      </div>
    </div>
    <div class="cont-bottom-search">
      <div class="left-bottom-search box-shadow radius">
        <div class="subtitle-page">
          Filters
        </div>
        <div class="flex-overflow-column cont-parameters" id="listfilters">
          <component :is="parameterComponent" />
        </div>
      </div>
      <div class="right-bottom-search box-shadow radius">
        <div
          v-if="current === 'post'"
          class="subtitle-page"
        >
          Newsfeed Results
        </div>
        <div
          v-if="current === 'offer'"
          class="subtitle-page"
        >
          Job Offers Results
        </div>
        <div
          v-if="current === 'talent'"
          class="subtitle-page"
        >
          Talents Results
        </div>
        <div
          v-if="current === 'company'"
          class="subtitle-page"
        >
          Companies Results
        </div>
        <div
          id="listresult"
          class="cont-results"
          :class="hasNext ? 'reduit' : ''"
        >
          <component :is="resultComponent" />
        </div>

      </div>
    </div>

  </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import PerfectScrollbar from 'perfect-scrollbar';
import results from '@/components/UniversalComponents/Search/Results';
import scrollInit from '@/modules/mixins/perfectScrollInit';
// import results from '@/components/UniversalComponents/Search/Results';
// import GlobalNav from '@/components/navigation/GlobalNav.vue';
import params from '@/components/UniversalComponents/Search/Params';
import newElemNav from '@/components/navigation/partialNavigation/NewElemNav.vue';
import companyResults from '@/components/UniversalComponents/Search/Results/companyResults.vue';
import offerResults from '@/components/UniversalComponents/Search/Results/offerResults.vue';
import postResults from '@/components/UniversalComponents/Search/Results/postResults.vue';
import talentResults from '@/components/UniversalComponents/Search/Results/talentResults.vue';
import SearchBar from '../../../components/UniversalComponents/Search/searchBar.vue';

export default {
  name: 'UniversalSearch',
  components: {
    // GlobalNav,
    newElemNav,
    SearchBar,
  },
  computed: {
    ...mapState('search', ['current']),
    ...mapState('user', ['userInfo']),
    resultComponent() {
      return results.find((view) => view.name === this.current).component;
    },
    parameterComponent() {
      return params.find((view) => view.name === this.$store.state.search.current).component;
    },
    hasNext() {
      return this.$store.getters[`search/${this.current}/nextPageAvailable`];
    },
    title() {
      return {
        company: 'Companies', offer: 'Offers', post: 'Newsfeed', talent: 'Talents',
      }[this.current];
    },
  },
  data() {
    return {
      tabs: [
        {
          name: 'Newsfeed',
          nametab: 'post',
          component: postResults,
          picto: 'picto_feed_purple',
          selected_picto: 'picto_feed',
        },
        {
          name: 'Job Offers',
          nametab: 'offer',
          component: offerResults,
          picto: 'picto_searchjob_purple',
          selected_picto: 'picto_searchjob_white',
        },
        {
          name: 'Talents',
          nametab: 'talent',
          component: talentResults,
          picto: 'picto_searchtalent_purple',
          selected_picto: 'picto_searchtalent_white',
        },
        {
          name: 'Companies',
          nametab: 'company',
          component: companyResults,
          picto: 'picto_searchcompany_purple',
          selected_picto: 'picto_searchcompany_white',
        },
      ],
    };
  },
  methods: {

    changeTab(newTab) {
      this.$store.dispatch('search/setCurrentSearch', newTab);
      this.goTop();
    },
    getNextPage() {
      return this.$store.dispatch(`search/${this.current}/getNextEntries`);
    },
    newTab(current) {
      const stringParams = JSON.stringify(this.$store.getters[`search/${current}/params`]);
      window.open(`${window.location.origin}/search?current=${current}&string=${this.searchText}&params=${stringParams}`, '_blank');
    },

    startScrollFilters() {
      const psfilters = new PerfectScrollbar('#listfilters', {
        wheelPropagation: false,
        suppressScrollX: true,
      });
      psfilters.update();
    },
    startScrollResults() {
      scrollInit('#listresult', () => this.getNextPage());
    },

    goTop() {
      const element = document.getElementById('listresult');
      this.$nextTick(() => {
        element.scrollTop = 0;
      });
    },

  },
  mounted() {
    this.$store.dispatch('user/getUserInfo');
    if (Object.keys(this.$route.query).length > 0) {
      if (this.$route.query.current) {
        this.$store.dispatch('search/setCurrentSearch', this.$route.query.current);
      }
      if (this.$route.query.string) {
        this.$store.dispatch('search/setSearchString', this.$route.query.string);
      }
      if (this.$route.query.params) {
        this.$store.dispatch(`search/${this.current}/getEntriesInitial`, JSON.parse(this.$route.query.params));
      }
      this.$router.replace('/search');
    }
    this.$store.dispatch('search/searchAll');
    this.startScrollFilters();
    this.startScrollResults();
  },

};
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  @import 'https://unpkg.com/vue-select@latest/dist/vue-select.css';

  .search-container{
    width: calc(100% - 2.2vh);
    padding: 1vh;
    border: 0.1vh solid $logo_color;
    margin-bottom: 2vh;
    .elem-search{
      .head-search{
        .autocomplete{
          .autocomplete-result-list{
            z-index: 1000 !important;
          }
        }
      }
    }
  }

  #listresult,
  #listfilters{
    overflow: hidden;
    position: relative;
  }

  .cont-filter{
    position: relative;
    .blockfilter{
      position: relative;
      margin-bottom: 1vh;
    }
  }

  .elem-search{
    > input{
      width: calc(100% - 2vh) !important;
    }
  }

  .cont-inner-nav{
    display: flex;
    flex-direction: row;
    margin-top: 2vh;
    margin-bottom: 4vh;
  }

  .cont-bottom-search{
    display:flex;
    height: 61vh;
    overflow: hidden;
    padding: 0.5vh;
    .left-bottom-search{
      flex: 5;
      background-color: $new_bg_grey;
      .cont-parameters{
        padding: 1vh;
        position: relative;
        overflow: hidden;
        height:55vh;
      }
    }
    .right-bottom-search{
      position: relative;
      flex: 10;
      background-color: $new_bg_grey;
      margin-left: 4vw;
      padding: 0 0.5vh;
      .cont-results{
        padding: 1vh;
        position: relative;
        overflow: hidden;
        height: 55vh;
        &.reduit{
          height: 50vh;
        }
        .inner-talent-entry{
          .elem-job{
            width: 50%;
          }
        }
      }
      .cont-nav{
        padding: 1vh;
        height: 3vh;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
    .subtitle-page{
      padding: 1vh;
    }
  }

</style>
