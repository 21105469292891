export default (type) => ({
  watch: {
    params: {
      handler(newVal) {
        this.$store.dispatch(`search/${type}/getEntriesInitial`, newVal);
      },
      deep: true,
    },
  },
  methods: {
    mapOptions(QueryOptions) {
      return QueryOptions.map((entry, index) => {
        const newEntry = {};
        if (typeof entry !== 'object') {
          newEntry.data = entry;
          newEntry.name = entry;
        } else if (entry.text) {
          newEntry.data = entry.picto;
          newEntry.name = entry.text;
        } else {
          newEntry.data = entry.data;
          newEntry.name = entry.name;
        }
        newEntry.id = index;
        return newEntry;
      });
    },
    toggleOption(data, field) {
      if (data === 'ALL') {
        this.params[field] = [];
      }
      if (!this.optionSelected(data, field)) {
        this.params[field].push(data);
      } else {
        this.params[field].splice(this.params[field].indexOf(data), 1);
      }
    },
    optionSelected(data, field) {
      return this.params[field] === data || this.params[field].indexOf(data) !== -1;
    },
    changeSelection(data, field) {
      this.params[field] = data;
    },
    clearAll() {
      this.$store.dispatch(`search/${type}/getEntriesInitial`, {});
    },

  },

});
